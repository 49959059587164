import React, { useState } from "react";
import ActionCurtain from "../../../../../components/actionCurtain/ActionCurtain";
import Button from "../../../../../components/button/Button";
import { FormWrapper } from "../../../../../components/form/FormWrapper/FormWrapper";
import { Switch } from "../../../../../components/form/Switch/Switch";
import { TextArea } from "../../../../../components/form/TextArea/TextArea";
import TextField from "../../../../../components/form/TextField/TextField";
import { IImage, ISite } from "../../../../../interfaces/Site";
import { Image } from "../../../../components/Image/Image";
import { UploadImage } from "../../../../components/UploadImage/UploadImage";
import { IGiftRegistryItem } from "../../../GiftRegistry";
import styles from "./EditGiftRegistryItem.module.scss";

interface IProps {
    closeEdit: () => void;
    giftRegistryItem: IGiftRegistryItem;
    updateGiftRegistryItem: (item: IGiftRegistryItem, newItem: IGiftRegistryItem) => void;
    addOrUpdateImageToSite: (image: IImage) => void;
    deleteItem: () => void;
    site: ISite;
}

export function EditGiftRegistryItem(props: IProps) {
    const [newGiftRegistryitem, setNewGiftRegistryitem] = useState(Object.assign({}, props.giftRegistryItem));

    const handleValueChange = (value: string | number, name: keyof IGiftRegistryItem) => {
        if (name === "quantity" && value && (value as string).length > 0) {
            value = parseInt(value as string);
        }
        setNewGiftRegistryitem({
            ...newGiftRegistryitem,
            [name]: value,
        });
    };

    const handleImageChange = (imageObject: any) => {
        setNewGiftRegistryitem({
            ...newGiftRegistryitem,
            image: imageObject,
        });
    };

    const deleteImage = () => {
        handleImageChange(null);
    };

    const handleDone = () => {
        props.updateGiftRegistryItem(props.giftRegistryItem, newGiftRegistryitem);
        props.closeEdit();
    };

    return (
        <ActionCurtain close={handleDone}>
            <FormWrapper submit={handleDone}>
                <div className={styles.wrapper}>
                    <div className={styles.formWrapper}>
                        <div className={styles.formFieldWrapper}>
                            <TextField
                                label="Rubrik"
                                name="title"
                                id="editGiftRegistryItemTitle"
                                onChangeCallback={handleValueChange}
                                value={newGiftRegistryitem.title}
                            />
                        </div>
                        <div className={styles.formFieldWrapper}>
                            <TextField
                                label="Länk"
                                name="link"
                                id="editGiftRegistryItemLink"
                                onChangeCallback={handleValueChange}
                                value={newGiftRegistryitem.link}
                                placeholder="https://www.google.se/"
                            />
                        </div>
                        <div className={styles.formFieldWrapper}>
                            <TextArea
                                label="Beskrivning"
                                name="description"
                                id="editGiftRegistryItemDescription"
                                onChangeCallback={handleValueChange}
                                value={newGiftRegistryitem.description}
                            />
                        </div>
                        <div className={styles.formFieldWrapperReservableAndQuantity}>
                            <div className={styles.reservableWrapper}>
                                <Switch
                                    label="Kan reserveras"
                                    name="reservable"
                                    handleChange={handleValueChange}
                                    value={newGiftRegistryitem.reservable}
                                    id="editGiftRegistryItemReservable"
                                    dark={true}
                                />
                            </div>

                            <div className={styles.quantityWrapper}>
                                {newGiftRegistryitem.reservable === true && (
                                    <TextField
                                        label="Önskat antal"
                                        number={true}
                                        name="quantity"
                                        id="editGiftRegistryItemQuantity"
                                        onChangeCallback={handleValueChange}
                                        min={1}
                                        preventUnmountUpdate={true}
                                        required={true}
                                        value={newGiftRegistryitem.quantity + ""} // + "" converts to string
                                    />
                                )}
                            </div>
                        </div>
                        <UploadImage
                            site={props.site}
                            addOrUpdateImageToSite={props.addOrUpdateImageToSite}
                            updateContentImage={handleImageChange}
                        />
                    </div>
                    {newGiftRegistryitem.image ? (
                        <div className={styles.imageEditWrapper}>
                            <div className={styles.deleteImageButton}>
                                <Button
                                    buttonText="Ta bort bild"
                                    type="secondary"
                                    callback={deleteImage}
                                    fullWidth={true}
                                />
                            </div>
                            <div className={styles.imageWrapper}>
                                <Image image={newGiftRegistryitem.image} site={props.site} sizes={null} />
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className={styles.bottomButtons}>
                    <div className={styles.deleteButton}>
                        <Button buttonText="Ta bort från önskelista" type="warning" callback={props.deleteItem} />
                    </div>
                    <div className={styles.doneButton}>
                        <Button buttonText="Klar" type="primary" submit={true} fullWidth={true} />
                    </div>
                </div>
            </FormWrapper>
        </ActionCurtain>
    );
}
