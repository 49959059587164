import React, { useCallback, useEffect, useRef, useState } from "react";
import EditButton from "../../components/EditButton/EditButton";
import { IconEdit } from "../../icons/edit";
import { IconMenu } from "../../icons/menu";
import { ISite } from "../../interfaces/Site";
import styles from "./Menu.module.scss";
import MenuItem from "./MenuItem/MenuItem";
import useOnClickOutside from "../../utils/useOnClickOutside";

interface IProps {
    site: ISite;
    editMode: boolean;
    showEditMenu: () => void;
    editAvailable: boolean;
}

export const Menu = (props: IProps) => {
    const [toggled, setToggled] = useState(false);
    const [navigationFits, setNavigationFits] = useState<boolean | null>(null);
    const ref = useOnClickOutside(() => setToggled(false), toggled);

    const toggleMenu = () => {
        setToggled(!toggled);
    };

    const allMenuItemsIsHidden = (): boolean => {
        if (props.site.modules && props.site.modules.length) {
            const numberOfNotHiddenMenuItems = props.site.modules.filter((module) => {
                return module.hideInMenu !== true;
            }).length;
            return numberOfNotHiddenMenuItems ? false : true;
        } else {
            return true;
        }
    };

    const elementRef = useRef<HTMLDivElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const updateNavigationFits = useCallback(() => {
        const element = elementRef?.current;
        const elementWrapper = wrapperRef?.current;
        if (!element || !elementWrapper) return;
        const errorMargin = 2;
        const menuWidth = element.offsetWidth || 0;
        const menuWrapperWidth = (elementWrapper.offsetWidth || 0) - errorMargin;
        if (menuWidth >= menuWrapperWidth) {
            setNavigationFits(false);
        } else {
            setNavigationFits(true);
        }
    }, []);

    useEffect(() => {
        const elementWrapper = wrapperRef?.current;
        if (!elementWrapper) return;

        if ("ResizeObserver" in window) {
            const observer = new ResizeObserver((entries) => {
                updateNavigationFits();
            });

            observer.observe(elementWrapper);
            return () => {
                observer.disconnect();
            };
        } else {
            updateNavigationFits();
        }
    }, [wrapperRef, updateNavigationFits]);

    useEffect(() => {
        updateNavigationFits();
    }, [props.site, updateNavigationFits]);

    if (props.editMode !== true && allMenuItemsIsHidden()) {
        return null;
    }
    return (
        <>
            <div
                className={`${styles[props.site.theme]} ${toggled ? styles.toggled : ""} ${props.editAvailable ? styles.editAvailable : ""} ${
                    navigationFits ? styles.navigationFits : ""
                }`}
                ref={ref}
            >
                <div
                    className={`${styles.toggleButton} ${toggled ? styles.toggled : ""} ${
                        navigationFits === null ? styles.hideUntilMesurmentIsDone : null
                    }`}
                    onClick={toggleMenu}
                >
                    <IconMenu></IconMenu>
                    Meny
                </div>
                <nav
                    className={`${styles.menu} ${toggled ? styles.toggled : ""} ${
                        navigationFits ? styles.navigationFits : ""
                    } ${navigationFits === null ? styles.hideUntilMesurmentIsDone : null}`}
                >
                    <div className={styles.innerWrapper}>
                        <div className={styles.editMenuButtonWrapper}>
                            {props.editMode === true ? (
                                <EditButton callback={props.showEditMenu}>
                                    <>
                                        <IconEdit></IconEdit>
                                        Ändra meny
                                    </>
                                </EditButton>
                            ) : null}
                        </div>

                        <ul>
                            {props.site.modules
                                .filter(
                                    (module) => module.hideInMenu !== true && (module.hide !== true || props.editMode)
                                )
                                .map((module) => (
                                    <li key={module.id}>
                                        <MenuItem
                                            module={module}
                                            site={props.site}
                                            closeMenu={() => setToggled(false)}
                                        />
                                    </li>
                                ))}
                        </ul>
                        <div className={styles.rightSpacer}></div>
                    </div>
                </nav>
                <div className={`${styles.menu} ${styles.navigationFits} ${styles.measurementOnly}`} ref={wrapperRef}>
                    <div className={styles.innerWrapper} ref={elementRef}>
                        <div className={styles.editMenuButtonWrapper}></div>

                        <ul>
                            {props.site.modules
                                .filter(
                                    (module) => module.hideInMenu !== true && (module.hide !== true || props.editMode)
                                )
                                .map((module) => (
                                    <li key={module.id}>
                                        <MenuItem
                                            measurementOnly={true}
                                            module={module}
                                            site={props.site}
                                            closeMenu={() => setToggled(false)}
                                        />
                                    </li>
                                ))}
                        </ul>
                        <div className={styles.rightSpacer}></div>
                    </div>
                </div>
            </div>
        </>
    );
};
